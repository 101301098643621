import * as api from '@/redux/api/notifications';
import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { DeliveryType } from '@/redux/api/alerts';
import { fetchBidLimitBalanceIfNeeded } from './bidLimit';
import {
    fetchCatalogRegistrationsForUserByIdIfNeeded,
    fetchLiveCatalogRegistrationByIdIfNeeded,
} from './catalog/registration/catalogRegistration.actions';
import { fetchItemSummariesByIds } from '@/redux/modules/item/summary/itemSummary.api';
import { formatDistance, parseISO } from 'date-fns';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getCatalogSellerId, getCatalogStatus } from './catalog/catalogs/catalog.selectors';
import { getDeployment, getPushSubscriptionPublicKey } from './config';
import { getImageUrl, GetImageUrlParams, urlSafeTitle } from '@/utils/urls';
import { LOG_OUT_BIDDER } from '@/redux/modules/account/logout/logout.actions';
import { NotificationPermissions } from '@/enums/notificationPermissions.enum';
import { OPEN_DOWNLOAD_MOBILE_MODAL } from './actions';
import { setInvoiceReminderCookie } from './cookies';
import { toggleSmsAlert } from './smsAlerts';
import { v4 as uuidv4 } from 'uuid';
import ms from 'ms';
import type * as PubNubTypes from '@/types/pubnub';
import type { FetchItemsSummariesResponse } from '@/types/item/ItemSummary';
import type { Notification } from '@/types/Notification';

/* Action Types */
export const LOAD_NOTIFICATIONS_FAIL = 'la/domain/notifications/LOAD_FAIL';
export const LOAD_NOTIFICATIONS_REQUEST = 'la/domain/notifications/LOAD_REQUEST';
export const LOAD_NOTIFICATIONS_SUCCESS = 'la/domain/notifications/LOAD_SUCCESS';

export const MARK_DELETED_FAIL = 'la/domain/notification/MARK_DELETED_FAIL';
export const MARK_DELETED_REQUEST = 'la/domain/notification/MARK_DELETED_REQUEST';
export const MARK_DELETED_SUCCESS = 'la/domain/notification/MARK_DELETED_SUCCESS';

export const MARK_READ_FAIL = 'la/domain/notification/MARK_READ_FAIL';
export const MARK_READ_REQUEST = 'la/domain/notification/MARK_READ_REQUEST';
export const MARK_READ_SUCCESS = 'la/domain/notification/MARK_READ_SUCCESS';

export const PUBNUB_BIDDER_NOTIFICATION_RECEIVED = 'la/domain/notification/PUBNUB_BIDDER_NOTIFICATION_RECEIVED';

export const SET_PERMISSION = 'la/domain/notification/SET_PERMISSION';
export const SET_PERMISSION_OVERLAY = 'la/domain/notification/SET_PERMISSION_OVERLAY';

export const SET_SUBSCRIPTION_FAIL = 'la/domain/notifications/SET_SUBSCRIPTION_FAIL';
export const SET_SUBSCRIPTION_REQUEST = 'la/domain/notifications/SET_SUBSCRIPTION_REQUEST';
export const SET_SUBSCRIPTION_SUCCESS = 'la/domain/notifications/SET_SUBSCRIPTION_SUCCESS';

export const DISABLE_SUBSCRIPTION_FAIL = 'la/domain/notifications/DISABLE_SUBSCRIPTION_FAIL';
export const DISABLE_SUBSCRIPTION_REQUEST = 'la/domain/notifications/DISABLE_SUBSCRIPTION_REQUEST';
export const DISABLE_SUBSCRIPTION_SUCCESS = 'la/domain/notifications/DISABLE_SUBSCRIPTION_SUCCESS';

export const FORCE_ENABLE_NOTIFICATIONS_BANNER = 'la/domain/notifications/FORCE_ENABLE_NOTIFICATIONS_BANNER';
export const FORCE_ENABLE_NOTIFICATIONS_BANNER_DISMISS =
    'la/domain/notifications/FORCE_ENABLE_NOTIFICATIONS_BANNER_DISMISS';

const ERROR_TIMEOUT = ms('1m');

/* reducer */
export type NotificationSlice = {
    errorTime: number;
    forceEnableBanner: boolean;
    hasActiveSubscription: boolean;
    hasNewNotifications: boolean;
    loading: boolean;
    notificationCount: number;
    notifications: Notification[];
    permission: NotificationPermissions;
    pushNotifications: Notification[];
    showPermissionOverlay: boolean;
};

export const defaultNotificationSlice: NotificationSlice = {
    errorTime: 0,
    forceEnableBanner: false,
    hasActiveSubscription: false,
    hasNewNotifications: false,
    loading: false,
    notificationCount: 0,
    notifications: [],
    permission: NotificationPermissions.Unset,
    pushNotifications: [],
    showPermissionOverlay: false,
};

export default function reducer(state = defaultNotificationSlice, action: any = {}): NotificationSlice {
    let filteredNotifications;
    switch (action.type) {
        case LOAD_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                errorTime: 0,
                hasNewNotifications: false,
                loading: false,
                notificationCount: [...action.payload.filter((x) => !x.isRead)].length,
                notifications: [...action.payload],
            };
        case MARK_DELETED_SUCCESS:
            filteredNotifications = state.notifications.filter((x) => !action.payload.includes(x.id));
            return {
                ...state,
                notificationCount: state.notificationCount,
                notifications: filteredNotifications,
            };
        case MARK_READ_SUCCESS:
            return {
                ...state,
                notificationCount: state.notificationCount - action.payload.length,
            };
        case PUBNUB_BIDDER_NOTIFICATION_RECEIVED:
            return {
                ...state,
                hasNewNotifications: true,
                pushNotifications: [...state.pushNotifications, action.payload],
            };
        case SET_PERMISSION:
            return {
                ...state,
                permission: action.payload,
                pushNotifications: [],
            };
        case SET_PERMISSION_OVERLAY:
            return {
                ...state,
                showPermissionOverlay: action.payload,
            };
        case SET_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                hasActiveSubscription: true,
            };
        case DISABLE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                hasActiveSubscription: false,
            };
        case LOAD_NOTIFICATIONS_FAIL:
            return {
                ...state,
                errorTime: action.meta.actionTime,
                loading: false,
            };
        case LOAD_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FORCE_ENABLE_NOTIFICATIONS_BANNER:
            return {
                ...state,
                forceEnableBanner: true,
            };
        case FORCE_ENABLE_NOTIFICATIONS_BANNER_DISMISS:
            return {
                ...state,
                forceEnableBanner: false,
            };
        case LOG_OUT_BIDDER:
            return defaultNotificationSlice;
        case MARK_DELETED_FAIL:
        case MARK_DELETED_REQUEST:
        case MARK_READ_FAIL:
        case MARK_READ_REQUEST:
        case SET_SUBSCRIPTION_FAIL:
        case SET_SUBSCRIPTION_REQUEST:
        case DISABLE_SUBSCRIPTION_FAIL:
        case DISABLE_SUBSCRIPTION_REQUEST:
            return state;
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const notificationSlice = createSelector(stateSelector, (state) => state.notification);
const userStateSelector = (state: GlobalState) => state.user;

export const getNotifications = createSelector(notificationSlice, (state) => state.notifications || []);

export const getNotificationCount = createSelector(
    notificationSlice,
    (notification) => notification.notificationCount || 0
);

export const getHasNewNotifications = createSelector(
    notificationSlice,
    (notification) => notification.hasNewNotifications || false
);

export const getPushNotifications = createSelector(notificationSlice, (state) => state.pushNotifications);

export const getPermission = createSelector(notificationSlice, (state) => state.permission);

export const getShowPermissionsOverlay = createSelector(notificationSlice, (state) => state.showPermissionOverlay);

export const getHasActiveSubscription = createSelector(notificationSlice, (state) => state.hasActiveSubscription);

export const getIsLoading = createSelector(notificationSlice, (state) => state.loading);

export const getNeedsSubscription = createSelector(
    [userStateSelector, getHasActiveSubscription, getPermission],
    (userState, hasActiveSubscription, permission) =>
        !hasActiveSubscription && permission === NotificationPermissions.Granted && Boolean(userState.token)
);

export const getErrorTime = createSelector(notificationSlice, (state) => state.errorTime || 0);

export const getForceEnableBanner = createSelector(notificationSlice, (state) => state.forceEnableBanner);

/* ACTION CREATORS */
export const createPubnubBidderNotificationsOnNotificationMessageReceived =
    (originalBidderId: number) =>
    (message: PubNubTypes.BidderNotificationMessageEvent) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        const { message: msg } = message;

        if (msg.bidderId === originalBidderId) {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            let notification;
            if (msg.itemId) {
                const itemIds = [msg.itemId];
                const item = await fetchItemSummariesByIds({
                    authToken,
                    deployment,
                    identifier: 'create bidder notification',
                    itemIds,
                });
                notification = transformPushNotificationWithItems(msg, item);
            } else if (msg.notificationType === 'registrationChangedData') {
                dispatch(updateRegistrationStatus(msg.catalogId));
            } else {
                notification = transformPushNotification(msg);
            }
            dispatch({
                payload: notification,
                type: PUBNUB_BIDDER_NOTIFICATION_RECEIVED,
            });
        }
    };

export const createInvoiceMustPayPopup =
    (originalBidderId: number) =>
    (message: PubNubTypes.BidderInvoiceReminderEvent) =>
    async (dispatch: AppDispatch) => {
        const { message: invoiceReminder } = message;
        if (invoiceReminder.bidderId === originalBidderId) {
            const imageUrls = [];
            if (invoiceReminder.itemIds.length > 0) {
                invoiceReminder.itemIds.forEach((itemId) => {
                    const imageOptions: GetImageUrlParams = {
                        catalogId: invoiceReminder.catalogId,
                        height: 70,
                        itemId: itemId,
                        sellerId: invoiceReminder.sellerId,
                    };
                    const url = getImageUrl({ ...imageOptions, quality: 15 });
                    imageUrls.push(url);
                });
            }
            dispatch(setInvoiceReminderCookie());
        }
    };

const updateRegistrationStatus = (catalogId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    if (catalogId) {
        return Promise.all([
            dispatch(
                fetchCatalogRegistrationsForUserByIdIfNeeded({
                    catalogIds: [catalogId],
                    force: true,
                })
            ),
            dispatch(fetchBidLimitBalanceIfNeeded({ catalogId, force: true })),
        ]).then(() => {
            const state = getState();
            const catalogStatus = getCatalogStatus(state, catalogId);
            const sellerId = getCatalogSellerId(state, catalogId);
            if (catalogStatus === 'live') {
                // adding a little delay to ensure that dante has had a chance to update after getting the same notification
                setTimeout(
                    () =>
                        dispatch(
                            fetchLiveCatalogRegistrationByIdIfNeeded({
                                catalogId,
                                force: true,
                                sellerId,
                            })
                        ),
                    1500
                );
            }
        });
    }
    return Promise.resolve();
};

export const loadNotifications = (token?: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        if (!token && !authToken) {
            return Promise.resolve();
        }

        dispatch({
            type: LOAD_NOTIFICATIONS_REQUEST,
        });

        const notifications = await api.fetchNotifications({
            authToken: token || authToken,
            deployment,
        });
        const itemIds = notifications.payload?.map((x) => x.itemId).filter((x) => x) ?? [];
        let items = {
            error: false,
            message: '',
            payload: {
                items: [],
                pageCount: 0,
                recordCount: 0,
                totalRecords: 0,
            },
        };
        if (itemIds.length) {
            items = await fetchItemSummariesByIds({
                authToken: token || authToken,
                deployment,
                identifier: 'load notification',
                itemIds,
            });
        }
        const transformedNotifications = transformNotificationsWithItems(notifications.payload, items.payload.items);
        dispatch({
            payload: transformedNotifications,
            type: LOAD_NOTIFICATIONS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { actionTime: Date.now() },
            payload: error,
            type: LOAD_NOTIFICATIONS_FAIL,
        });
    }
};

const shouldFetchNotifications = (state) => {
    const token = getAuthToken(state);
    const loading = getIsLoading(state);
    const hasNewNotifications = getHasNewNotifications(state);
    const errorTime = getErrorTime(state);
    let errorLimit = false;
    if (Boolean(errorTime)) {
        const diff = Date.now() - errorTime;
        errorLimit = diff <= ERROR_TIMEOUT;
    }
    return Boolean(token) && !loading && Boolean(hasNewNotifications) && !errorLimit;
};

export const fetchNotificationsIfNeeded =
    (force?: boolean, authToken?: string) => (dispatch: AppDispatch, getState: AppGetState) => {
        if (shouldFetchNotifications(getState()) || force) {
            return dispatch(loadNotifications(authToken));
        }
        return Promise.resolve();
    };

export const forceEnableNotificationsBanner = () => (dispatch: AppDispatch) => {
    const result = dispatch({
        type: FORCE_ENABLE_NOTIFICATIONS_BANNER,
    });
    return Promise.resolve(result);
};

export const forceEnableNotificationsBannerDismiss = () => (dispatch: AppDispatch) => {
    const result = dispatch({
        type: FORCE_ENABLE_NOTIFICATIONS_BANNER_DISMISS,
    });
    return Promise.resolve(result);
};

export const markNotificationsDeleted =
    (notificationIds: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            if (!authToken) {
                return;
            }
            dispatch({
                payload: notificationIds,
                type: MARK_DELETED_REQUEST,
            });
            await api.postMarkDeleted({
                authToken,
                deployment,
                notificationIds,
            });
            dispatch({
                payload: notificationIds,
                type: MARK_DELETED_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: MARK_DELETED_FAIL,
            });
        }
    };

export const markNotificationsRead = (notificationIds: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        if (!authToken) {
            return;
        }
        dispatch({
            payload: notificationIds,
            type: MARK_READ_REQUEST,
        });
        await api.postMarkRead({ authToken, deployment, notificationIds });
        dispatch({
            payload: notificationIds,
            type: MARK_READ_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: MARK_READ_FAIL,
        });
    }
};

export const setPermission = (permission: any) => async (dispatch: AppDispatch) => {
    if (!permission) {
        dispatch(disableWebPushSubscription());
    }
    return dispatch({
        payload: permission,
        type: SET_PERMISSION,
    });
};

export const openMobileDownloadModal = () => (dispatch: AppDispatch) => {
    dispatch({
        type: OPEN_DOWNLOAD_MOBILE_MODAL,
    });
};

export const getBrowserPermission = () => async (dispatch: AppDispatch) => {
    if (!window || !window.Notification) {
        return dispatch({
            payload: NotificationPermissions.Unsupported,
            type: SET_PERMISSION,
        });
    } else {
        dispatch({
            payload: window.Notification?.permission,
            type: SET_PERMISSION,
        });
        if (Notification?.permission === NotificationPermissions.Granted) {
            dispatch(configureWebPushSubscription());
        }
    }
};

export const requestBrowserPermission = () => async (dispatch: AppDispatch) => {
    if (!window.Notification) {
        //browser doesn't support notifications
        return dispatch({
            payload: NotificationPermissions.Unsupported,
            type: SET_PERMISSION,
        });
    } else {
        dispatch({
            payload: true,
            type: SET_PERMISSION_OVERLAY,
        });
        window.Notification.requestPermission(async (reply) => {
            if (reply === NotificationPermissions.Granted) {
                dispatch(toggleSmsAlert(0, DeliveryType.browser, true));
                sendInitialNotification();
                dispatch(configureWebPushSubscription());
            } else {
                dispatch(toggleSmsAlert(0, DeliveryType.browser, false));
            }
            dispatch({
                payload: reply,
                type: SET_PERMISSION,
            });
            dispatch({
                payload: false,
                type: SET_PERMISSION_OVERLAY,
            });
        });
    }
};

export const configureWebPushSubscription = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        if (supportsPushNotifications()) {
            dispatch({ type: SET_SUBSCRIPTION_REQUEST });
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const key = getPushSubscriptionPublicKey(state);
            const subscription = await updateSubscription(key);
            if (!authToken) {
                return;
            }
            await api.postUpdateWebPushSubscription({
                authToken,
                deployment,
                subscription,
            });
            dispatch({ type: SET_SUBSCRIPTION_SUCCESS });
        }
    } catch (error) {
        dispatch({
            meta: error,
            type: SET_SUBSCRIPTION_FAIL,
        });
    }
};

const disableWebPushSubscription = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        dispatch({ type: DISABLE_SUBSCRIPTION_REQUEST });
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        await api.postDisableWebPushSubscription({ authToken, deployment });
        dispatch({ type: DISABLE_SUBSCRIPTION_SUCCESS });
    } catch (e) {
        dispatch({
            type: DISABLE_SUBSCRIPTION_FAIL,
        });
    }
};

const transformTimeStamp = (date) => {
    const time = parseISO(date);
    const now = new Date();
    return formatDistance(time, now, {
        addSuffix: true,
    });
};

export const transformLink = (link: any) => {
    let usableLink = link;
    usableLink = link.includes('/item/') ? `/item/${urlSafeTitle(link.split('/item/').pop())}` : usableLink;
    usableLink = link.includes('/console/') ? `/console/${urlSafeTitle(link.split('/console/').pop())}` : usableLink;
    usableLink = link.includes('/catalog/') ? `/catalog/${urlSafeTitle(link.split('/catalog/').pop())}` : usableLink;
    return usableLink;
};

const transformPushNotification = (message) => {
    let notification = message;
    notification.id = uuidv4();
    return notification;
};

const sendInitialNotification = () => {
    new window.Notification('From LiveAuctioneers', {
        body: 'Your notifications are enabled!',
    });
};

export const transformNotificationsWithItems = (notifications, items) => {
    notifications?.forEach((n) => {
        if (n.itemId) {
            const item = items.filter((i) => i.itemId === n.itemId)[0];
            let imageUrl = '';
            if (item) {
                const { catalogId, imageVersion, itemId, photos, sellerId } = item;
                const imageOptions: any = {
                    catalogId,
                    imageNumber: photos?.[0] || 1,
                    imageVersion,
                    itemId,
                    sellerId,
                };
                imageUrl = getImageUrl(imageOptions);
            }
            n.imageUrl = imageUrl;
        }
        n.link = transformLink(n.link);
        n.timestamp = transformTimeStamp(n.createdAt);
    });
    return notifications || [];
};

const transformPushNotificationWithItems = (message, data: FetchItemsSummariesResponse) => {
    let notification = message;
    let imageUrl = '';
    if (data?.payload?.items?.[0]) {
        const { catalogId, imageVersion, itemId, sellerId } = data.payload.items[0];
        const imageOptions = {
            catalogId,
            imageNumber: 1,
            imageVersion,
            itemId,
            sellerId,
        };
        imageUrl = getImageUrl(imageOptions);
    }
    notification.imageUrl = imageUrl;
    notification.id = uuidv4();
    return notification;
};

const supportsPushNotifications = () => {
    return !(!navigator || !window || !('serviceWorker' in navigator) || !('PushManager' in window));
};
const registerServiceWorker = async () => {
    const swRegistration = await navigator.serviceWorker.register('../../pushNotificationServiceWorker.js');
    return swRegistration;
};

const updateSubscription = async (subscriptionKey) => {
    const swRegistration = await registerServiceWorker();
    const applicationServerKey = urlBase64ToUint8Array(subscriptionKey);
    const options = { applicationServerKey, userVisibleOnly: true };
    const subscription = await swRegistration.pushManager.subscribe(options);
    const sub = subscription.toJSON();
    const newSub = {
        endpoint: sub.endpoint,
        keys: sub.keys,
    };
    return newSub;
};

// urlBase64ToUint8Array is a magic function that will encode the base64 public key
// to Array buffer which is needed by the subscription option
const urlBase64ToUint8Array = (base64String) => {
    var padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    var base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};
