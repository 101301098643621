import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { fetchOtherCategories } from '@/redux/api/otherCategories/otherCategories';
import { getDeployment } from '@/redux/modules/config';
import {
    LOAD_OTHER_CATEGORIES_FAILURE,
    LOAD_OTHER_CATEGORIES_REQUEST,
    LOAD_OTHER_CATEGORIES_SUCCESS,
} from '@/redux/modules/actions/otherCategories/otherCategories';
import {
    LoadOtherCategoriesFailureAction,
    LoadOtherCategoriesRequestAction,
    LoadOtherCategoriesSuccessAction,
    OtherCategoriesReducerAction,
    OtherCategoriesSlice,
} from '@/redux/modules/otherCategories/otherCategories.types';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

export const getPageNumberAndSizeKey = (pageNumber: number, pageSize: number) => `${pageNumber}|${pageSize}`;

export const defaultOtherCategoriesState: OtherCategoriesSlice = {
    byPageNumberAndSize: {},
    loaded: {},
    loading: [],
    totalResults: 0,
};

const getLoadRequestState = (
    state: OtherCategoriesSlice,
    { payload }: LoadOtherCategoriesRequestAction
): OtherCategoriesSlice => ({
    ...state,
    loading: union(state.loading, [payload.pageNumberAndSize]),
});

const getLoadSuccessState = (
    state: OtherCategoriesSlice,
    { meta, payload }: LoadOtherCategoriesSuccessAction
): OtherCategoriesSlice => ({
    ...state,
    byPageNumberAndSize: {
        ...state.byPageNumberAndSize,
        [meta.pageNumberAndSize]: payload.discoverCategories,
    },
    loaded: {
        ...state.loaded,
        [meta.pageNumberAndSize]: meta.actionTime,
    },
    loading: difference(state.loading, [meta.pageNumberAndSize]),
    totalResults: payload.totalResults,
});

const getLoadFailureState = (
    state: OtherCategoriesSlice,
    { meta }: LoadOtherCategoriesFailureAction
): OtherCategoriesSlice => ({
    ...state,
    loading: difference(state.loading, [meta.pageNumberAndSize]),
});

const reducer = (state: OtherCategoriesSlice, action: OtherCategoriesReducerAction): OtherCategoriesSlice => {
    const populatedState = state || defaultOtherCategoriesState;
    switch (action.type) {
        case LOAD_OTHER_CATEGORIES_REQUEST:
            return getLoadRequestState(populatedState, action as LoadOtherCategoriesRequestAction);
        case LOAD_OTHER_CATEGORIES_SUCCESS:
            return getLoadSuccessState(populatedState, action as LoadOtherCategoriesSuccessAction);
        case LOAD_OTHER_CATEGORIES_FAILURE:
            return getLoadFailureState(populatedState, action as LoadOtherCategoriesFailureAction);
        default:
            return populatedState;
    }
};

export default reducer;

export const OTHER_CATEGORIES_STORE_TIME = ms('1h');

const shouldFetchOtherCategories = (state: GlobalState, pageNumberAndSize: string): boolean => {
    const { loaded, loading } = state.otherCategories;
    const pageLoadedTime = loaded[pageNumberAndSize];
    const pageIsLoading = loading.indexOf(pageNumberAndSize) > -1;
    if (!pageLoadedTime && !pageIsLoading) {
        return true;
    }
    const now = Date.now();
    const diff = now - pageLoadedTime;
    return diff >= OTHER_CATEGORIES_STORE_TIME;
};

/**
 * @param pageNumber - which page is the user viewing
 * @param pageSize - how many items are there per page
 * @return Void
 * If the otherCategory page is on the store and not outdated, that will be maintained
 * Otherwise, an API request will be made
 */
export const fetchOtherCategoriesIfNeeded =
    (pageNumber: number, pageSize: number) =>
    async (dispatch: AppDispatch, getState: AppGetState): Promise<void> => {
        const pageNumberAndSize = getPageNumberAndSizeKey(pageNumber, pageSize);
        try {
            if (shouldFetchOtherCategories(getState(), pageNumberAndSize)) {
                const requestAction: LoadOtherCategoriesRequestAction = {
                    error: false,
                    payload: { pageNumberAndSize },
                    type: LOAD_OTHER_CATEGORIES_REQUEST,
                };
                dispatch(requestAction);

                const deployment = getDeployment(getState());
                const response = await fetchOtherCategories({
                    deployment,
                    pageNumber,
                    pageSize,
                });
                const successAction: LoadOtherCategoriesSuccessAction = {
                    error: false,
                    meta: { actionTime: Date.now(), pageNumberAndSize },
                    payload: response.payload,
                    type: LOAD_OTHER_CATEGORIES_SUCCESS,
                };
                dispatch(successAction);
            }
        } catch (error) {
            const failureAction: LoadOtherCategoriesFailureAction = {
                error: true,
                meta: { pageNumberAndSize },
                payload: error,
                type: LOAD_OTHER_CATEGORIES_FAILURE,
            };
            dispatch(failureAction);
        }
    };
