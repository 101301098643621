import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getDeployment } from './config';
import { PayloadlessAction } from '@/types/redux';
import { postResetPassword } from '@/redux/api/auth';

/* Action Types */
const PASSWORD_RESET_FAIL = 'la/ui/passwordReset/FAIL';
export const PASSWORD_RESET_REQUEST = 'la/ui/passwordReset/REQUEST';
export type PasswordResetRequestAction = PayloadlessAction<typeof PASSWORD_RESET_REQUEST>;
export const PASSWORD_RESET_SUCCESS = 'la/ui/passwordReset/SUCCESS';

// reducer
export type PasswordResetSlice = {
    error: boolean;
    errorMessage: any;
    submitted: boolean;
    success: boolean;
};

export const defaultPasswordResetSlice: PasswordResetSlice = {
    error: false,
    errorMessage: null,
    submitted: false,
    success: false,
};

export default function reducer(
    state: PasswordResetSlice = defaultPasswordResetSlice,
    action: any = {}
): PasswordResetSlice {
    switch (action.type) {
        case PASSWORD_RESET_REQUEST:
            return {
                ...state,
                error: false,
                errorMessage: null,
                submitted: true,
                success: false,
            };
        case PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                submitted: false,
                success: true,
            };
        case PASSWORD_RESET_FAIL:
            return {
                ...state,
                error: true,
                errorMessage: action.payload,
                submitted: false,
                success: false,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;

export const getUiPasswordReset = createSelector(stateSelector, (state) => state.passwordReset);

/* ACTION CREATORS */
type SubmitPasswordResetParams = {
    bidderId: number;
    hash: string;
    newPassword: string;
};
export const submitPasswordReset =
    ({ bidderId, hash, newPassword }: SubmitPasswordResetParams) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);
            dispatch({
                payload: bidderId,
                type: PASSWORD_RESET_REQUEST,
            });
            await postResetPassword({
                bidderId,
                deployment,
                hash,
                newPassword,
            });
            dispatch({
                meta: { actionTime: Date.now() },
                type: PASSWORD_RESET_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { bidderId },
                payload: error,
                type: PASSWORD_RESET_FAIL,
            });
        }
    };
