import { HYDRATE_CLIENT_STATE } from './actions';
import { parseQueryString } from '@/utils/queryParams';
import { pastFilterOptions, upcomingFilterOptions } from '@/enums/myBidsFilterOptions';
import cloneDeep from 'lodash/cloneDeep';

import { AppDispatch } from '@/redux/store';
import { REHYDRATE } from 'redux-persist/constants';
import { setPaginationPageSizeCookie } from './cookies';

const MAX_VIEW_COUNT_FOR_SEARCH = 120;
const UNSUPPORT_SEARCH_VIEW_COUNT = 'all';

/* Action Types */
export const CHANGE_PAGINATION_SORT_ORDER = 'la/ui/pagination/sortOrder';
export const CHANGE_PAGINATION_PAGE_SIZE = 'la/ui/pagination/pageSize';
const CHANGE_PAGINATION_FILTER = 'CHANGE_PAGINATION_FILTER';

// reducer
export const DEFAULT_LOCAL_STORAGE_STATE = {
    filter: {
        ended: pastFilterOptions.WON,
        upcoming: upcomingFilterOptions.ALL,
    },
    sort: {},
};

export interface DefaultCookieState {
    pageSize: number | 'all';
}

export const DEFAULT_COOKIE_STATE: DefaultCookieState = {
    pageSize: 24,
};

export type LocalStorageState = typeof DEFAULT_LOCAL_STORAGE_STATE;

export type CookieState = typeof DEFAULT_COOKIE_STATE;

export function paginationLocalStorageReducer(
    state: LocalStorageState = DEFAULT_LOCAL_STORAGE_STATE,
    action: any = {}
) {
    let filter;
    switch (action.type) {
        case REHYDRATE:
            if (
                Object.prototype.hasOwnProperty.call(action.payload, 'persist') &&
                Object.prototype.hasOwnProperty.call(action.payload.persist, 'pagination')
            ) {
                const incoming = action.payload.persist.pagination;
                return { ...state, ...incoming };
            }

            return state;
        case CHANGE_PAGINATION_SORT_ORDER:
            return {
                ...state,
                sort: {
                    ...state.sort,
                    [action.payload.type]: action.payload.sortOrder,
                },
            };
        case CHANGE_PAGINATION_FILTER:
            filter = cloneDeep(state.filter);
            filter[action.payload.tab] = action.payload.filter;
            return {
                ...state,
                filter,
            };
        default:
            return state;
    }
}

export function paginationCookiesReducer(state: CookieState = DEFAULT_COOKIE_STATE, action: any = {}) {
    switch (action.type) {
        case CHANGE_PAGINATION_PAGE_SIZE:
            return {
                ...state,
                pageSize: action.payload,
            };
        case HYDRATE_CLIENT_STATE:
            return {
                ...state,
                pageSize: action.payload.pageSize,
            };
        default:
            return state;
    }
}

/* SELECTORS */
// Because this is global it will not be able to return a default.
export function getPaginationSortOrder(state: any, type: string) {
    return state.persist.pagination.sort[type] || '';
}

export function getPaginationPageSize(state: any) {
    const urlParams = parseQueryString(state.route?.currentUrl?.search, {
        ignoreQueryPrefix: true,
    });

    if (urlParams.hasOwnProperty('pageSize')) {
        const { pageSize } = urlParams;
        return pageSize !== 'all' ? Number(pageSize) : pageSize;
    }

    return state.pagination.pageSize || 24;
}

export function getPaginationFilter(state: any, tab: string) {
    return state.persist.pagination.filter[tab];
}

export const getSearchPageSize = (state: any) => {
    const pageSize = getPaginationPageSize(state);
    return pageSize === UNSUPPORT_SEARCH_VIEW_COUNT ? Number(MAX_VIEW_COUNT_FOR_SEARCH) : Number(pageSize);
};

/* ACTION CREATORS */
export const changePaginationSortOrder = (type: any, sortOrder: any) => ({
    payload: { sortOrder, type },
    type: CHANGE_PAGINATION_SORT_ORDER,
});

export const changePaginationFilter = (tab: any, filter: any) => ({
    payload: { filter, tab },
    type: CHANGE_PAGINATION_FILTER,
});

export const changePaginationPageSize = (pageSize: number | 'all') => (dispatch: AppDispatch) => {
    dispatch(setPaginationPageSizeCookie(pageSize));
    return dispatch({
        payload: pageSize !== 'all' ? Number(pageSize) : pageSize,
        type: CHANGE_PAGINATION_PAGE_SIZE,
    });
};

export const changeSellerPaginationSortOrder = (type: string, sortOrder: string) => async (dispatch: AppDispatch) =>
    dispatch(changePaginationSortOrder(type, sortOrder));
