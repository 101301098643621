import { $Values } from 'utility-types';
import {
    ADD_ZIP_PAYMENT_CARD_FAIL,
    ADD_ZIP_PAYMENT_CARD_REQUEST,
    ADD_ZIP_PAYMENT_CARD_SUCCESS,
    DISMISS_MODAL,
} from './actions';
import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { BidderAddress } from '@/types/BidderAddresses';
import { createSelector } from '@reduxjs/toolkit';
import { fetchWonItemsIfNeeded } from './wonItems/wonItems.actions';
import { getAuthToken, getBidderId, getSessionId } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import { getSignifydSessionId } from './signifyd';
import { PaymentMethods } from '@/enums';
import { postLAPayment } from '@/redux/api/payment';
import paymentErrorCodes from '@/enums/paymentErrorCodes';

/* Action Types */
export const SUBMIT_PAYMENT_FAIL = 'la/ui/payment/FAIL';
export const SUBMIT_PAYMENT_REQUEST = 'la/ui/payment/REQUEST';
export const SUBMIT_PAYMENT_SUCCESS = 'la/ui/payment/SUCCESS';

export type PaymentSlice = {
    error: string;
    errorCode: $Values<typeof paymentErrorCodes> | string;
    submitted: boolean;
    success: boolean;
};

export const defaultPaymentSlice: PaymentSlice = {
    error: '',
    errorCode: '',
    submitted: false,
    success: false,
};

export default function reducer(state: PaymentSlice = defaultPaymentSlice, action: any = {}): PaymentSlice {
    switch (action.type) {
        case ADD_ZIP_PAYMENT_CARD_REQUEST:
        case SUBMIT_PAYMENT_REQUEST:
            return {
                error: '',
                errorCode: '',
                submitted: true,
                success: false,
            };
        case ADD_ZIP_PAYMENT_CARD_SUCCESS:
        case SUBMIT_PAYMENT_SUCCESS:
            return {
                ...state,
                submitted: false,
                success: true,
            };
        case ADD_ZIP_PAYMENT_CARD_FAIL:
            return {
                ...state,
                error: action.payload,
                submitted: false,
                success: false,
            };
        case SUBMIT_PAYMENT_FAIL:
            return {
                ...state,
                errorCode: action.payload,
                submitted: false,
                success: false,
            };
        case DISMISS_MODAL:
            return defaultPaymentSlice;
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const paymentSlice = createSelector(stateSelector, (state) => state.payment);
export const getPaymentSubmitted = createSelector(paymentSlice, (state: PaymentSlice) => state.submitted);
export const getPaymentSuccess = createSelector(paymentSlice, (state: PaymentSlice) => state.success);
export const getPaymentError = createSelector(paymentSlice, (state: PaymentSlice) => state.error);
export const getPaymentErrorCode = createSelector(paymentSlice, (state: PaymentSlice) => state.errorCode);

/* ACTION CREATORS */
export type SubmitLAPaymentParams = {
    installmentFee: number;
    invoiceId: number;
    paymentMethod: PaymentMethods;
    paymentToken?: string;
    phoneNumber: string;
    shippingAddress: BidderAddress;
    shippingMethod?: string;
};
export const submitLAPayment =
    ({
        installmentFee,
        invoiceId,
        paymentMethod,
        paymentToken,
        phoneNumber,
        shippingAddress,
        shippingMethod,
    }: SubmitLAPaymentParams) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const bidderId = getBidderId(state);
            const deployment = getDeployment(state);
            const riskifiedSessionId = getSessionId();
            const signifydSessionId = getSignifydSessionId(state);
            dispatch({
                payload: bidderId,
                type: SUBMIT_PAYMENT_REQUEST,
            });
            const response = await postLAPayment({
                authToken,
                bidderId,
                deployment,
                installmentFee,
                invoiceId,
                paymentMethod,
                paymentToken,
                phoneNumber,
                riskifiedSessionId,
                shippingAddress,
                shippingMethod,
                signifydSessionId,
            });
            dispatch(fetchWonItemsIfNeeded({ force: true, page: 1, pageSize: 10 }));

            return dispatch({
                meta: { actionTime: Date.now() },
                payload: response.data,
                type: SUBMIT_PAYMENT_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: SUBMIT_PAYMENT_FAIL,
            });
            return Promise.reject(error);
        }
    };
